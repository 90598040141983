import React, { useContext } from "react";
import BusinessCalculator from "../components/BusinessCalculator/BusinessCalculator";
import BusinessClientReviews from "../components/BusinessClientReviews/BusinessClientReviews";
import BusinessIconText from "../components/BusinessIconText/BusinessIconText";
import BusinessImpactNew from "../components/BusinessImpactNew/BusinessImpactNew";
import BusinessCards from "../components/BusinessCards/BusinessCards";
import ClientCardsScroller from "../components/ClientCardsScroller/ClientCardsScroller";
import ContactFormPipe from "../components/ContactFormPipe/ContactFormPipe";
import Faq from "../components/Faq/Faq";
import Intro from "../components/Intro_v2/Intro_v2";
import Meta from "../components/Meta/Meta";
import BusinessClients from "../components/BusinessClients/BusinessClients";
import Spacer from "../components/Spacer/Spacer";
import SectionTwoCol from "../components/SectionTwoCol/SectionTwoCol";
import { DataContext } from "../contexts/DataContext";
import Hero3BC from "../components/Hero3BC/Hero3BC";
import { Helmet } from "react-helmet";

const Business = () => {
  const { 
    labels, 
    pageLabels, 
    currentLanguageSuffix, 
    i18nOasiLink,
    languages,
    pageImages,
  } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";

  const products = pageLabels.cards;

  return (
    <>
      <Meta />

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(products.map(e => {
            return {
              "@context":"https://schema.org",
              "@type": "Service",
              "name": e.title,
              "brand": {
                "@type": "Brand",
                "name": "3Bee",
              },
              "description": e.subtitle,
            };
          }))}
        </script>
      </Helmet>

      <Hero3BC 
        title = {pageLabels.hero.title}
        image = "/2020/images/Apicoltore_sensore_mano.jpg"
        paragraph = {pageLabels.hero.paragraph}
        buttonText = {pageLabels.hero.cta}
        to = "#products"
        newsletterButtonText = {pageLabels.hero.newsletterButtonText}
        newsletterTitle = {pageLabels.hero.newsletterTitle}
        titleBackground = "transparent"
        lang={currentLanguageCode}
        success = {pageLabels.hero.newsletterSuccessText}
        error="Error. Contact us on info@3bee.com"
        leftButtonProps={{
          text: pageLabels.hero.leftButtonProps.text,
          to: "#products",
        }}
        newsletterListType = "businessPage"
        allImages = {pageImages}
      />
        
      <Spacer size={15}/>
      <BusinessClients 
        title={pageLabels.clients.title}
        subtitle={pageLabels.clients.subtitle}
        logos={[
          { image: pageLabels.logos[0].image, slug: null },
          { image: pageLabels.logos[1].image, slug: null },
          { image: pageLabels.logos[2].image, slug: null },
          { image: pageLabels.logos[3].image, slug: null },
          { image: pageLabels.logos[4].image, slug: null },
          { image: pageLabels.logos[5].image, slug: null },
          { image: pageLabels.logos[6].image, slug: null },
          { image: pageLabels.logos[7].image, slug: null },
        ]}
      />

      <Spacer size={5}/>
      <BusinessImpactNew
        image="/2020/images/sfondo_colorato.jpg"
        title={pageLabels.impact.title}
        subtitle={pageLabels.impact.subtitle}
        item1={pageLabels.impact.item1}
        item2={pageLabels.impact.item2}
        item3={pageLabels.impact.item3}
        item4={pageLabels.impact.item4}
      />
      <Spacer size={10}/>
      <Intro 
        bigText={pageLabels.story.title}
        textAlign="center"
      />
      <BusinessIconText
        items={[{
          image: "/2020/images/business/HR_icon.png",
          label: pageLabels.story.hr.label,
          text: pageLabels.story.hr.text,
        },{
          image: "/2020/images/business/communication_icon.png",
          label: pageLabels.story.comunication.label,
          text: pageLabels.story.comunication.text,
        },{
          image: "/2020/images/business/CSR_RSI_icon.png",
          label: pageLabels.story.csr.label,
          text: pageLabels.story.csr.text,
        }]}
      />

      <Spacer size={20}/>

      <Intro 
        id="products"
        bigText={pageLabels.products.title}
        subtitle={pageLabels.products.subtitle}
      />
      
      <BusinessCards
        id="BusinessProducts"
        cards = {pageLabels.cards}
      />
      
      <Spacer size={10}/>
      <Intro 
        bigText={pageLabels.addons.title}
        textAlign="center"
      />
      <BusinessIconText
        items={[{
          image: "/2020/images/business/addons/polly.png",
          label: pageLabels.addons.polly.label,
          text: pageLabels.addons.polly.text,
        },{
          image: "/2020/images/business/addons/workshop.png",
          label: pageLabels.addons.workshop.label,
          text: pageLabels.addons.workshop.text,
        },{
          image: "/2020/images/business/addons/apiary_experience.png",
          label: pageLabels.addons.apiary.label,
          text: pageLabels.addons.apiary.text,
        },{
          image: "/2020/images/business/addons/gift.png",
          label: pageLabels.addons.gift.label,
          text: pageLabels.addons.gift.text,
        }]}
      />

      <Spacer size={20}/>
      
      <BusinessCalculator/>
      <SectionTwoCol
        title={pageLabels.oasi.title}
        subtitle1={pageLabels.oasi.subtilte}
        cta={pageLabels.oasi.button}
        to={"#contacts"}
        image={`/2020/images/business${currentLanguageSuffix === "" 
          ? "/" 
          : `${currentLanguageSuffix}-`}mobile.png`}
      />

      <div style={{ height: "5.5rem" }}></div>

      <Intro 
        bigText={pageLabels.impactability.title}
        subtitle={pageLabels.impactability.subtitle}
      />
      <ClientCardsScroller  
        items={[{
          logo: "/2020/images/csr/logo-save-the-duck.png",
          background: "/2020/images/csr/background-save-the-duck.jpg",
          text: pageLabels.impactability.companies.saveTheDuck,
        },{
          logo: "/2020/images/csr/logo-ferrero.png",
          background: "/2020/images/csr/background-ferrero.jpg",
          text: pageLabels.impactability.companies.ferrero,
          cta: {
            label: labels.discoverMore,
            url: i18nOasiLink("/owner/ferrero"),
          },
        },{
          logo: "/2020/images/csr/logo-actimel.png",
          background: "/2020/images/csr/background-actimel.jpg",
          text: pageLabels.impactability.companies.actimel,
          cta: {
            label: labels.discoverMore,
            url: i18nOasiLink("/owner/actimel"),
          },
        },{
          logo: "/2020/images/csr/beeit_logo.jpg",
          background: "/2020/images/csr/background_bee_it.jpg",
          text: pageLabels.impactability.companies.bee_it,
          cta: {
            label: labels.discoverMore,
            url: i18nOasiLink("/owner/beeit-1"),
          },
        },{
          logo: "/2020/images/csr/logo-crai.png",
          background: "/2020/images/csr/background-crai.jpg",
          text: pageLabels.impactability.companies.crai,
        },{
          logo: "/2020/images/csr/logo-sobi.png",
          background: "/2020/images/csr/background-sobi.jpg",
          text: pageLabels.impactability.companies.sobi,
          cta: {
            label: labels.discoverMore,
            url: i18nOasiLink("/owner/sobi"),
          },
        },{
          logo: "/2020/images/csr/logo-nen.png",
          background: "/2020/images/csr/background-nen.jpg",
          text: pageLabels.impactability.companies.nen,
          cta: {
            label: labels.discoverMore,
            url: "/nen",
          },
        },{
          logo: "/2020/images/csr/logo-inside-technologies.png",
          background: "/2020/images/csr/background-inside-technologies.jpg",
          text: pageLabels.impactability.companies.insideTechnologies,
          cta: {
            label: labels.discoverMore,
            url: i18nOasiLink("/owner/inside-technologies-srl-1"),
          },
        },{
          logo: "/2020/images/csr/logo-noverim.png",
          background: "/2020/images/csr/background-noverim.jpg",
          text: pageLabels.impactability.companies.noverim,
          cta: {
            label: labels.discoverMore,
            url: "/noverim",
          },
        },{
          logo: "/2020/images/csr/logo-hills.png",
          background: "/2020/images/csr/background-hills.jpg",
          text: pageLabels.impactability.companies.hills,
          cta: {
            label: labels.discoverMore,
            url: "/hillspet",
          },
        },{
          logo: "/2020/images/csr/mflaw.png",
          background: "/2020/images/csr/background-mflaw.jpg",
          text: pageLabels.impactability.companies.mflaw,
          cta: {
            label: labels.discoverMore,
            url: "/mflaw",
          },
        },{
          logo: "/2020/images/csr/cosmosol.jpg",
          background: "/2020/images/csr/background-cosmosol.jpg",
          text: pageLabels.impactability.companies.cosmosol,
          cta: {
            label: labels.discoverMore,
            url: "/cosmosol",
          },
        }]}
      />

      <div style={{ height: "5.5rem" }}></div>

      <Intro 
        bigText={pageLabels.reviews.title}
        subtitle={pageLabels.reviews.subtitle}
      />
      <BusinessClientReviews
        reviews={pageLabels.reviews.dataFile.reviews}
      />

      <div style={{ height: "5.5rem" }}></div>

      <ContactFormPipe />
      <Spacer size={10}/>
      <Faq
        data={pageLabels.faq.dataFile.data}
      />
    </>
  );
};

export default Business;

/*
<HeroImageContainer style={{ position: "relative", minHeight: "500px" }}>
          <GatsbyImage loading="eager" layout="fullWidth" objectFit="cover" objectPosition="center" opacity="1" style={{ "max-height": "750px", "display":"block" }}
            image={getImage(imageData)} alt={imageData.alt} />
          <div style={{
            position: "absolute", zIndex: 1, top: 0, left: 0, width: "100%", background: "linear-gradient(rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.2) 30%, rgba(255, 255, 255, 0.2) 72%, rgb(255, 255, 255) 100%)", 
          }} />
        </HeroImageContainer>
        <HeroHeader
          title={<StyledTitleHero  dangerouslySetInnerHTML={{ __html: pageLabels.hero.title  }} />}
          description={<StyledSubTitleHero dangerouslySetInnerHTML={{ __html: pageLabels.hero.paragraph  }} />}
          variant='transparent'>
          <div className="mt-8">
            <Button onClick={useNavigate("#businessimpact")}>{pageLabels.hero.cta}</Button>
          </div>
        </HeroHeader>
        <HeroFooter>
          <NewsletterBox
            title={pageLabels.hero.boxTitle}
            newsletterListId={getListId("businessPage")}
            newsletterButtonText={pageLabels.hero.newsletterButtonText}
            newsletterTitle={pageLabels.hero.newsletterTitle}
            lang={currentLanguageSuffix}
            inputProps={pageLabels.hero.inputProps}
            leftButtonProps={{
              text: pageLabels.hero.leftButtonProps.text,
              onClick: useNavigate("#products"),
            }}
            onSuccess={() => toast.success("Welcome in 3Bee, Bzz", {
              containerId: "Alert",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 10000,
            })}
            onError={() => toast.error("Error. Contact us on info@3bee.com", {
              containerId: "Alert",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 10000,
            })}
          />
        </HeroFooter>
      </Hero>
*/
