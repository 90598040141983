export const slideUp = {
  offscreen: {
    opacity: 0,
    scale: 0.4,
    y: "0px",
  },
  onscreen: {
    opacity: 1,
    scale: 1,
    y: "-135px",
    transition: {
      type: "spring",
      duration: 1.2,
    },
  },
};

export const fadeInShadow = {
  offscreen: {
    opacity: 1,
    y: "0px",
  },
  onscreen: {
    opacity: 1,
    y: "-15px",
    transition: {
      type: "spring",
      duration: 0.6,
    },
  },
};

export const fadeIn = {
  offscreen: {
    opacity: 0,
    y: "0px",
  },
  onscreen: {
    opacity: 1,
    y: "-15px",
    transition: {
      type: "spring",
      duration: 0.68,
    },
  },
};
