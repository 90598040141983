import styled, { css } from "styled-components";
import { BP, Rem, Colors } from "../../commons/Theme";
import { Button } from "@3beehivetech/components";
import { motion } from "framer-motion";

export const StyledDisclaimer = styled.p`
  font-size: ${Rem(10)};
  font-weight: 300; 
  text-align: center;
  line-height: 2rem;
  color: ${Colors.white};
  min-height: 32px;
`;

export const StyledBusinessCards = styled.div``;

export const StyledBusinessCardsItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:after {
    content: '';
    display: block;
    min-width: ${Rem(50)};
    max-width: ${Rem(50)};
    height: 1px;

    @media (${BP.ipad}) {
      display: none;
    }
  }

  @media (${BP.ipad}) {
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: ${Rem(40)};
  }

  ${(props) => 
    props.isValentine
    && css`
      padding-top: 8rem;
      padding-bottom: 3rem;
      @media (${BP.ipad}) {
        padding-top: 10rem;
        flex-wrap: nowrap;
        justify-content: flex-start;
      }
      @media (${BP.desktop}) {
        justify-content: space-evenly;
      }
      
    `}
`;

export const StyledBusinessCardsItemsGroup = styled.div`
  display: flex;

  @media (${BP.ipad}) {
    flex-direction: column;
    gap: ${Rem(40)};
  }
`;

export const StyledBusinessCardsItem = styled(motion.div)`
  position: relative;
  color: white;
  margin-left: ${Rem(16)};
  min-width: ${Rem(300)};
  height: ${Rem(450)};
  @media (${BP.ipad}) {
    min-width: ${Rem(370)};
    max-width: ${Rem(370)};
    height: ${Rem(400)};
    margin-left: 0;
    ${(props) => 
    props.isValentine
    && css`
      height: ${Rem(430)};
    `}
  }
  .gatsby-image-wrapper {
    min-width: ${Rem(300)};
    height: ${Rem(450)};
    @media (${BP.ipad}) {
      width: 100%;
      height: ${Rem(400)};
    ${(props) => 
    props.isValentine
      && css`
        height: ${Rem(430)};
    `}
    }
  }
`;

export const StyledImageAnimationWrapper = styled(motion.div)`
  position: absolute;
  top: -5%;
  left: 34%;
  width: 140px;
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledShadowWrapper = styled(motion.div)`
  position: absolute;
  bottom: -13%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  .gatsby-image-wrapper {
    max-width: 90%;
    min-width: 90%;
    height: 100%;
  }
`;

export const StyledShadowBigWrapper = styled(StyledShadowWrapper)`
  .gatsby-image-wrapper {
    max-width: 100%;
    min-width: 100%;
    height: 100%;
  }
`;

export const StyledBusinessCardsItemContent = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${Rem(20)};
  padding: ${Rem(20)};
  ${(props) => 
    props.isValentine
    && css`
      padding: 2rem;
      gap: ${Rem(10)};
    `}
`;

export const StyledBusinessCardsItemTitle = styled.h2`
  font-size: ${Rem(24)};
  line-height: ${Rem(30)};
  font-weight: 700;
  margin-top: auto;

  @media (${BP.tablet}) {
    font-size: ${Rem(30)};
    line-height: ${Rem(38)};
    ${(props) => 
    props.isValentine
    && css`
      font-size: ${Rem(24)};
    `}
  }
`;

export const StyledBusinessCardsItemSubtitle = styled.h3`
  font-size: ${Rem(14)};
  line-height: ${Rem(19)};
  font-weight: normal;

  @media (${BP.tablet}) {
    font-size: ${Rem(20)};
    line-height: ${Rem(25)};
  }
  ${(props) => 
    props.isValentine
    && css`
      min-height: 125px;
      @media (${BP.tablet}) {
        font-size: ${Rem(16)};
      }
  `}
`;

export const StyledButton = styled(Button)`
  ${(props) => 
    props.isValentine
    && css`
      padding: 1rem 1.75rem;
  `}
`;
