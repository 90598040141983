import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import useNavigate from "../../hooks/useNavigate";
import Scroller from "../Scroller/Scroller";
import { useAnimation } from "framer-motion";
import {
  StyledBusinessCards,
  StyledBusinessCardsItems,
  StyledBusinessCardsItem,
  StyledBusinessCardsItemContent,
  StyledBusinessCardsItemTitle,
  StyledBusinessCardsItemSubtitle,
  StyledButton,
  StyledDisclaimer,
  StyledImageAnimationWrapper,
  StyledImg,
  StyledShadowWrapper,
  StyledShadowBigWrapper,
} from "./style";
import { StyledGridRow } from "../../commons/Grid";
import { slideUp, fadeIn, fadeInShadow } from "./animation";

const BusinessCardsItem = ({
  title,
  subtitle,
  button,
  link,
  image,
  imageAnimated,
  onClickButton,
  disclaimer,
  isValentine,
}) => {
  const { pageImages } = useContext(DataContext);
  const imageData = pageImages.find((e) => e.path === image);
  const imageAnimationShadow = (image) => pageImages.find((e) => e.path === image);
  const startAnimate = useAnimation();
  
  return (
    <StyledBusinessCardsItem
      isValentine={isValentine}
      whileHover={{ y: isValentine ? "15px" : "0px" }} 
      onHoverStart={() => startAnimate.start("onscreen")}
      onHoverEnd={() => startAnimate.start("offscreen")}
    >
      {imageAnimated && isValentine && (
        <>
          <StyledImageAnimationWrapper
            initial="offscreen"
            animate={startAnimate}
            variants={slideUp}
          >
            <StyledImg
              src={imageAnimated}
              alt={imageData?.alt}
            />
          </StyledImageAnimationWrapper>
          <StyledShadowWrapper
            initial="offscreen"
            animate={startAnimate}
            variants={fadeInShadow}
          >
            <GatsbyImage
              image={getImage(imageAnimationShadow("/san-valentino/shadow.png"))}
              alt={imageData?.alt}
            />
          </StyledShadowWrapper>
          <StyledShadowBigWrapper
            initial="offscreen"
            animate={startAnimate}
            variants={fadeIn}
          >
            <GatsbyImage
              image={getImage(imageAnimationShadow("/san-valentino/shadow-big.png"))}
              alt={imageData?.alt}
            />
          </StyledShadowBigWrapper>
        </>
      )}

      <GatsbyImage
        imgStyle={{ borderRadius: "10px", filter: "blur(0px); brightness(100%);" }}
        image={getImage(imageData)}
        alt={imageData?.alt}
      />
      <div style={{
        borderRadius: "10px", position: "absolute", zIndex: 1, top: 0, left: 0, width: "100%", height:"100%", background: `linear-gradient(rgba(0, 0, 0, 0) ${isValentine ? "10%" : "40%"}, rgba(0, 0, 0,0.8) 90%)`, 
      }} />
      <StyledBusinessCardsItemContent isValentine={isValentine}>
        <StyledBusinessCardsItemTitle
          dangerouslySetInnerHTML={{ __html: title }}
          isValentine={isValentine}
        />
        <StyledBusinessCardsItemSubtitle
          dangerouslySetInnerHTML={{ __html: subtitle }}
          isValentine={isValentine}
        />

        <StyledButton
          style={{ display: button && link ? "block" : "none" }}
          onClick={useNavigate(link, onClickButton)}
          isValentine={isValentine}
        >
          {button}
        </StyledButton>
        {isValentine && (<StyledDisclaimer dangerouslySetInnerHTML={{ __html: disclaimer }}/>)}
      </StyledBusinessCardsItemContent>
    </StyledBusinessCardsItem>
  );
};

const BusinessCards = ({ cards, isValentinePage }) => {
  return (
    <StyledGridRow isFullMobile id="businessCard">
      <StyledBusinessCards>
        <Scroller>
          <StyledBusinessCardsItems isValentine={isValentinePage}>
            {cards.map((card, id) => (
              <BusinessCardsItem
                key={id}
                className="swiper-slide"
                title={card.title}
                subtitle={card.subtitle}
                button={card.button}
                link={card.link}
                image={card.image}
                imageAnimated={card.animationImage}
                isValentine={isValentinePage}
                disclaimer={card.disclaimer}
              />
            ))}
          </StyledBusinessCardsItems>
        </Scroller>
      </StyledBusinessCards>
    </StyledGridRow>
  );
};

export default BusinessCards;
